<template>
  <div id="inside" v-if="showNoContractsOrAgreements">
    <div class="container">
      <div class="row">
        <div
          class="align-items-center justify-content-center col-sm-12 col-12 d-flex col-md-12 col-lg-12 px-4"
        >
          <i
            class="fa fa-2x fa-exclamation-circle pull-left mr-4 mb-2 text-primary"
          ></i>
          <h4 class="py-4 text-primary">
            No momento não temos uma oferta disponível para você.
          </h4>
        </div>
      </div>
      <div class="row pb-5">
        <div
          class="align-items-center justify-content-center col-sm-12 col-12 d-flex col-md-12 col-lg-12 px-4"
        >
          <h6 class="py-2 text-muted">
            Conte com a gente para ter um alívio financeiro! É só selecionar o
            produto e acionar o atendimento que preferir:
          </h6>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12 col-lg-3 px-4">
          <h4 class="py-4">Central de Relacionamento Cartão de Crédito</h4>
          <p>
            <a href="tel:40043600" target="_blank">4004 3600</a> - Capitais e
            regiões metropolitanas.&nbsp;<br /><a
              href="tel:08007277477"
              target="_blank"
              >0800 727 7477</a
            >
            - Demais localidades.&nbsp;<br /><a
              href="tel:551133663216"
              target="_blank"
              >+55 11 3366 3216</a
            >
            - Atendimento no exterior.&nbsp;<br />De segunda a sexta-feira, das
            8h às 18h30.
          </p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">
            Central de Relacionamento Cartão de Crédito Empresarial
          </h4>
          <p>
            <a href="tel:1140043600" target="_blank">4004 3600</a> - Capitais e
            regiões metropolitanas.<br /><a
              href="tel:08007277477"
              target="_blank"
              >0800-727-7477</a
            >
            - Demais localidades.&nbsp;<br />De segunda à sexta das 7h40 às
            19h00.
          </p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">
            Central de Relacionamento Empréstimo e Financiamento
          </h4>
          <p>
            <a href="tel:40045215" target="_blank">4004 5215</a>&nbsp;<br />De
            segunda a sexta-feira, das 8h às 19h (exceto feriados).
          </p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">SAC</h4>
          <p>
            <a href="tel:08007272745" target="_blank">0800 727 2745</a
            ><br />Atendimento 24 horas.
          </p>
        </div>
      </div>
    </div>
    <HomeOptions />
  </div>
  <div id="inside" v-else>
    <div class="container mt-2">
      <div
        v-bind:class="[
          largura < 500 ? 'col-12 col-lg-12 p-2' : 'col-12 col-lg-12 p-0',
        ]"
      >
        <div class="row">
          <div class="col-6">
            <h5 class="text-wrapper-2 m-0" style="color: #2c3da8">
              Olá, {{ user.FullName.split(" ")[0] }}!
            </h5>
          </div>
          <div class="col-6 text-right">
            <p class="text-info mb-0" style="font-style: italic">
              CPF
              {{
                formatDocument(user.DocNumber).split(".")[0].substring(0, 6) +
                ".xxx.xxx-" +
                formatDocument(user.DocNumber).split("-")[1]
              }}
            </p>
          </div>
          <div class="col-12">
            <p class="mb-2 mt-4" style="font-style: italic; color: black">
              Confira as opções de negociação disponíveis:
            </p>
          </div>
          <div class="col-12" v-if="thereIsDebts">
            <h5 class="text-bold mt-2" style="color: black">
              Negociações disponíveis
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <!--row debts-->

      <div v-bind:class="[largura < 500 ? '' : '']" style="">
        <div
          v-bind:class="[
            largura < 500
              ? ''
              : 'd-inline-flex flex-wrap flex-column col-acao mt-0 p-0 col-12 col-sm-6 col-md-6 col-lg-4',
          ]"
          v-for="(value, key, index) in debtsCdc"
          :key="`${'a' + key}-${index}`"
        >
          <div v-bind:class="[largura < 500 ? 'card mb-4 m-2' : 'card mr-4']">
            <div class="card-header pr-3 pl-3 pt-3 pb-0">
              <div class="row">
                <div class="col-12">
                  <h5 style="color: black">{{ value.product }}</h5>
                  <p style="color: black">Contrato: {{ value.contract }}</p>
                </div>
              </div>
              <hr class="mt-0" />
            </div>
            <div class="card-body pr-3 pl-3 pt-0 pb-3">
              <div class="row">
                <div class="col-12 col-lg-12">
                  <p class="mb-1">Valor total para negociação:</p>
                  <p class="" style="font-weight: 700; font-size: 14pt">
                    R$
                    {{
                      value.originalValue.toLocaleString("pt-BR", {
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      })
                    }}
                  </p>
                </div>
                <div
                  class="d-flex col-12 col-lg-12 pr-0"
                  style="align-items: center"
                >
                  <p class="mb-1">Data da parcela mais atrasada:&nbsp;</p>
                  <h6 class="mt-1">
                    {{
                      formatData(
                        value.installments[0].dueDate,
                        "YYYY-MM-DD",
                        "L"
                      )
                    }}
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="text-center col-md-12 mt-3">
                  <button
                    class="btn mb-2 btn-primary p-3"
                    style="width: 100%; border-radius: 20px; font-size: 18px"
                    :disabled="
                      (checkedParcels.length <= 0 && value.selected) ||
                      thereIsAgreementCdcClosed(value.contract)
                    "
                    @click="offersCdc(value)"
                  >
                    NEGOCIAR
                  </button>
                </div>
              </div>
              <div
                class="row mt-3 mb-4 px-2"
                v-if="thereIsAgreementCdcClosed(value.contract)"
              >
                <div class="col-lg-1 text-center px-1 col-1">
                  <i class="fa fa-info-circle text-danger"></i>
                </div>
                <div class="px-1 col-lg-11 col-11">
                  <p class="small">
                    Existe um acordo em andamento, por isso não é possível fazer
                    uma nova negociação.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-bind:class="[
            largura < 500
              ? ''
              : 'd-inline-flex flex-wrap flex-column col-acao mt-0 p-0 col-12 col-sm-6 col-md-6 col-lg-4',
          ]"
          v-for="(value2, key2, index2) in debts"
          :key="`${'b' + key2}-${index2}`"
        >
          <div
            v-bind:class="[largura < 500 ? 'card mb-4 m-2' : 'card mr-4']"
            style="min-height: 265px"
          >
            <div class="card-header pl-3 pt-3 pr-3">
              <div class="row">
                <div class="col-12">
                  <h5 class="mb-0" style="color: black">
                    {{ value2.product }}&nbsp;
                  </h5>
                  <p style="font-size: 10pt">Contrato: {{ value2.contract }}</p>
                </div>
              </div>
              <hr class="mt-0 mb-2" />
            </div>
            <div
              v-bind:class="[
                largura < 500 ? 'card-body pt-1 pl-3 pr-3' : 'card-body pt-2',
              ]"
            >
              <div class="row">
                <div class="d-flex col-lg-12 col-12">
                  <p class="mb-1 mr-2" style="font-size: 10pt">
                    Valor original da dívida:
                  </p>
                  <h6 class="text-bold">
                    {{
                      formatFloatToReal(parseFloat(value2.originalValue, 10))
                    }}
                  </h6>
                </div>
                <div class="d-flex col-12 col-lg-12 mt">
                  <p class="mb-1 mr-2" style="font-size: 10pt">
                    Vencimento original da dívida:
                  </p>
                  <p class="mb-0" style="color: black">
                    {{ formatData(value2.dueDate, "YYYY-MM-DD", "L") }}
                  </p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="d-flex flex-column col-8">
                  <!-- <div
                    class="text-info"
                    style="display: flex; margin-bottom: 0"
                  >
                    <p class="mr-2 mb-0">De</p>
                    <span
                      style="text-decoration: line-through; margin-bottom: 0"
                    >
                      {{
                        formatFloatToReal(parseFloat(value2.paymentValue, 10))
                      }}
                    </span>
                    <p class="ml-2 mb-0">por</p>
                  </div> -->
                  <div class="mb-0" style="margin-bottom: 0; font-weight: bold">
                    {{
                      formatFloatToReal(parseFloat(value2.originalValue, 10))
                    }}
                    à vista
                  </div>
                  <div class="mb-0" style="margin-bottom: 0">
                    <p style="font-size: 9pt; margin-bottom: 0">
                      para pagamento até
                      {{ formatData(value2.offerDueDate, "YYYY-MM-DD", "L") }}
                    </p>
                  </div>
                  <div class="mb-0" style="margin-bottom: 0">
                    <p style="margin-bottom: 0; font-size: 11pt">
                      ou parcelado em até {{ value2.maxPaymentsOption }}x
                    </p>
                  </div>
                </div>

                <!-- <div class="col-4" v-if="value2.atSightPercentDiscount > 0">
                  <p class="mb-0" style="font-size: 8pt">Desconto de até</p>
                  <span
                    class="text-white"
                    style="
                      display: inline-block;
                      background-color: #88cd31;
                      border-radius: 20px;
                      padding: 5px 10px;
                      text-align: center;
                      width: auto;
                      margin-right: 15px;
                      font-size: 14pt;
                    "
                  >
                    {{ value2.atSightPercentDiscount }}%
                  </span>
                </div> -->
              </div>
              <div
                v-bind:class="[
                  largura < 500
                    ? 'row d-flex justify-content-center mt-1'
                    : 'row d-flex justify-content-center mt-3',
                ]"
              >
                <div
                  v-bind:class="[
                    largura < 500
                      ? 'text-center col-md-12 mt-4'
                      : 'text-center col-md-12 mt-3',
                  ]"
                >
                  <button
                    class="btn mb-2 btn-primary p-3"
                    style="width: 100%; border-radius: 20px; font-size: 18px"
                    @click="verBoletos(value2)"
                  >
                    EMITIR FATURA
                  </button>
                  <button
                    class="custom-button"
                    style=""
                    @click="offersFaturaPropostas(value2)"
                  >
                    NEGOCIAR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-bind:class="[
            largura < 500
              ? ''
              : 'd-inline-flex flex-wrap flex-column col-acao mt-0 p-0 col-12 col-sm-6 col-md-6 col-lg-4',
          ]"
          v-for="(value3, key3, index3) in debtsContencioso"
          :key="`${'c' + key3}-${index3}`"
        >
          <div
            v-bind:class="[largura < 500 ? 'card mb-4 m-2' : 'card mr-4']"
            style="min-height: 265px"
          >
            <div class="card-header">
              <div class="row">
                <div class="col-12">
                  <h5 class="mb-0" style="color: black">
                    {{ value3.product }}&nbsp;
                  </h5>
                  <p style="font-size: 10pt">Contrato: {{ value3.contract }}</p>
                </div>
              </div>
              <hr class="mt-0 mb-2" />
            </div>
            <div
              v-bind:class="[largura < 500 ? 'card-body pt-1' : 'card-body']"
            >
              <div class="row">
                <div class="d-flex col-lg-12 col-12">
                  <p class="mb-1 mr-2" style="font-size: 10pt">
                    Valor original da dívida:
                  </p>
                  <h6 class="text-bold">
                    {{
                      formatFloatToReal(parseFloat(value3.originalValue, 10))
                    }}
                  </h6>
                </div>
                <div class="d-flex col-12 col-lg-12 mt">
                  <p class="mb-1 mr-2" style="font-size: 10pt">
                    Vencimento original da dívida:
                  </p>
                  <h6>{{ formatData(value3.dueDate, "YYYY-MM-DD", "L") }}</h6>
                </div>
              </div>
              <div class="row mt-3">
                <div
                  class=""
                  v-bind:class="
                    largura < 500
                      ? 'd-flex flex-column col-7'
                      : 'd-flex flex-column col-8'
                  "
                >
                <!-- v-if="
                      parseFloat(value3.paymentValue, 10) <
                      parseFloat(value3.originalValue, 10)
                    " -->
                  <div
                    class="text-info"
                    style="display: flex; margin-bottom: 0"
                    
                  >
                    <p class="mr-2 mb-0">De</p>
                    <span
                      style="text-decoration: line-through; margin-bottom: 0"
                    >
                      {{
                        formatFloatToReal(parseFloat(value3.paymentValue, 10))
                      }}
                    </span>
                    <p class="ml-2 mb-0">por</p>
                  </div>
                  <div class="mb-0" style="margin-bottom: 0; font-weight: bold">
                    {{ formatFloatToReal(parseFloat(value3.atSightValue, 10)) }}
                    à vista
                  </div>
                  <div class="mb-0" style="margin-bottom: 0">
                    <p style="font-size: 9pt; margin-bottom: 0">
                      para pagamento até
                      {{ formatData(value3.offerDueDate, "YYYY-MM-DD", "L") }}
                    </p>
                  </div>
                  <div
                    class="mb-0"
                    style="margin-bottom: 0"
                    v-if="value3.maxPaymentsOption !== null"
                  >
                    <p style="margin-bottom: 0; font-size: 11pt">
                      ou parcelado em até {{ value3.maxPaymentsOption }}x
                    </p>
                  </div>
                </div>

                <div
                  v-bind:class="
                    largura < 500
                      ? 'col-5 text-right discount'
                      : 'col-4 text-center discount'
                  "
                  v-if="value3.atSightPercentDiscount > 0"
                >
                  <p class="mb-0 mr-2" style="font-size: 8pt">
                    Desconto de até
                  </p>
                  <span class="discount-value" style="font-size: 13pt">
                    {{ value3.atSightPercentDiscount }}%
                  </span>
                </div>
              </div>
              <div
                v-bind:class="[
                  largura < 500
                    ? 'row d-flex justify-content-center mt-1'
                    : 'row d-flex justify-content-center mt-3',
                ]"
              >
                <div
                  v-bind:class="[
                    largura < 500
                      ? 'text-center col-md-12 mt-4'
                      : 'text-center col-md-12 mt-3',
                  ]"
                >
                  <button
                    class="btn btn-primary mr-2 mb-4 p-3"
                    style="width: 100%; border-radius: 20px"
                    @click="offersFaturaContencioso('negociar', value3)"
                  >
                    NEGOCIAR
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div>
            <hr class="mr-2 ml-2" style="border-top: 1px solid #b7b7b7" />
          </div> -->
        </div>
      </div>

      <!--row agreements-->
      <div
        v-bind:class="[largura < 500 ? '' : '']"
        style=""
        v-show="agreements.length > 0"
      >
        <div
          v-bind:class="[
            largura < 500
              ? 'd-flex p-2 mt-4'
              : agreements.length > 1
              ? 'd-flex col-12 mt-4 pr-0 pl-0'
              : 'd-flex col-4 mt-4 pr-0 pl-0',
          ]"
          style="justify-content: space-between"
          v-if="!loading"
        >
          <h5 class="text-bold mt-2">Meus acordos</h5>
          <a
            class="nav-link dropdown-toggle"
            href="#cardSection"
            id="userDropdown"
            role="button"
            @click="toggleDropdown"
            aria-haspopup="true"
            aria-expanded="dropdownOpen"
          >
            <i v-if="!dropdownOpen" class="fa fa-angle-down fa-lg"></i>
            <i v-if="dropdownOpen" class="fa fa-angle-up fa-lg"></i>
          </a>
        </div>
        <div v-if="largura > 500" class="col-8"></div>
        <div
          v-bind:class="[
            largura < 500
              ? ''
              : 'd-inline-flex flex-wrap flex-column col-acao mt-0 p-0 col-12 col-sm-6 col-md-6 col-lg-4',
          ]"
          v-for="(value4, key4, index4) in agreements"
          :key="`${'d' + key4}-${index4}`"
          aria-labelledby="userDropdown"
        >
          <div
            id="cardSection"
            v-bind:class="[largura < 500 ? 'card mb-4 m-2' : 'card mr-4']"
            v-show="dropdownOpen"
          >
            <div class="card-header">
              <div v-bind:class="[largura < 500 ? '' : '']" style="">
                <div class="col-12 pl-0">
                  <!-- <h6 class="float-right rounded px-2 py-1 small bg-light">
                    Negociação realizada
                  </h6> -->
                  <h5 style="color: black">
                    {{ value4.product.substring(0, 25) }}
                  </h5>
                </div>
              </div>
            </div>
            <hr class="mr-2 ml-2" />
            <div
              class="card-body"
              :style="largura < 500 ? 'margin-top: -15px;' : ''"
            >
              <div class="row">
                <div
                  class="d-flex col-12 col-lg-12"
                  style="justify-content: space-between"
                >
                  <p class="mb-1">Nº do contrato:</p>
                  <h6 class="">{{ value4.contract }}</h6>
                </div>
                <div
                  class="d-flex col-lg-12 col-12 py-0"
                  style="justify-content: space-between"
                >
                  <p class="mb-1">Valor negociado:</p>
                  <h6 class="">
                    R$
                    {{
                      value4.negociatedValue.toLocaleString("pt-BR", {
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      })
                    }}
                  </h6>
                </div>
                <div
                  class="d-flex col-lg-12 col-12 py-0"
                  style="justify-content: space-between"
                >
                  <p class="mb-1">Plano:</p>
                  <h6 class="" v-if="value4.installmentNumber <= 1">
                    À vista&nbsp;
                  </h6>
                  <h6 class="" v-if="value4.installmentNumber > 1">
                    {{ value4.installmentNumber }}x&nbsp;
                  </h6>
                </div>
              </div>
              <div class="row">
                <div
                  class="d-flex col-lg-12 col-12 py-0"
                  style="justify-content: space-between"
                >
                  <p class="mb-1">Condições:</p>
                  <h6
                    class="text-body"
                    v-if="value4.installmentNumber > 1"
                    :style="
                      caractersCount(value4) > 35 ? 'font-size: 10pt; margin-top: 3px;' : ''
                    "
                  >
                    Entrada R$
                    {{
                      parseFloat(value4.firstInstallmentValue).toLocaleString(
                        "pt-BR",
                        {
                          currency: "BRL",
                          minimumFractionDigits: 2,
                        }
                      )
                    }}
                    + {{ value4.installmentNumber - 1 }}x de R$
                    {{
                      parseFloat(value4.installmentsValue).toLocaleString(
                        "pt-BR",
                        {
                          currency: "BRL",
                          minimumFractionDigits: 2,
                        }
                      )
                    }}
                  </h6>

                  <h6 class="text-body" v-if="value4.installmentNumber <= 1">
                    {{ value4.installmentNumber }}x de R$
                    {{
                      parseFloat(value4.firstInstallmentValue).toLocaleString(
                        "pt-BR",
                        {
                          currency: "BRL",
                          minimumFractionDigits: 2,
                        }
                      )
                    }}
                  </h6>
                </div>
              </div>
              <div class="row">
                <div
                  class="d-flex col-12 col-lg-12 py-0"
                  style="justify-content: space-between"
                >
                  <p class="mb-1">Parcela(s) em aberto:</p>
                  <h6 class="">
                    {{ value4.overdueInstallments }}/{{
                      value4.installmentNumber
                    }}
                  </h6>
                </div>
                <div
                  class="d-flex col-12 col-lg-12 py-0"
                  style="justify-content: space-between"
                >
                  <p class="mb-1">Vencimento:</p>
                  <h6>{{ formatData(value4.dueDate, "YYYY-MM-DD", "L") }}</h6>
                </div>
              </div>
              <div class="row mt-3">
                <div class="text-center col-md-12">
                  <button
                    class="btn mb-2 btn-primary p-3"
                    style="width: 100%; border-radius: 20px"
                    @click="verBoletosSemDebts(value4)"
                  >
                    VER ACORDO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="loading">
      <ProgressLoading v-bind:loading="loading" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  formatCurrencieValueBRL as formatValue,
  formatData,
  formatDocument,
  formatStringToLowerCase,
  formatFloatToReal,
} from "@/utils/helpers";
import { mapState } from "vuex";
import ProgressLoading from "../../modules/auth/components/ProgressLoading.vue";
import HomeOptions from "./components/HeaderMenu.vue";
import { initializeGlobals } from "@/global";

export default {
  name: "Dashboard",
  components: {
    ProgressLoading,
    HomeOptions,
  },
  data() {
    return {
      showDebtsSum: true,
      debts: [],
      debtsContencioso: [],
      debtsCdc: [],
      agreements: [],
      showNoContractsOrAgreements: false,
      largura: null,
      selectAll: false,
      checkedParcels: [],
      loading: false,
      sumTotalInstallmentsCdc: null,
      totalInstallmentsCdcWithFees: null,
      errorMessage: {},
      pageLocation: null,
      userDataStorage: {},
      url: null,
      protocol: null,
      path: null,
      host: null,
      userAdm: {},
      ipUser: null,
      dropdownOpen: false,
      thereIsDebts: false,
    };
  },
  mounted() {
    this.$emit("updatePageView", this.pageLocation);
    localStorage.removeItem("authUser");
  },
  async created() {
    this.updateCustomData();
    this.ipUser = localStorage.getItem("ipUser");
    localStorage.setItem("currentPageName", "dashboard");
    this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
    this.userAdm = JSON.parse(localStorage.getItem("userAdm"));

    this.url = window.location.href;
    this.buildAndSetUserData(
      this.userDataStorage.cpfCnpj,
      this.userDataStorage.id,
      this.url,
      "Dashboard"
    );

    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.path = window.location.pathname;
    this.pageLocation = this.protocol + "//" + this.host + this.path;

    this.largura = window.screen.width;
    this.loading = true;

    try {
      const tag = localStorage.getItem("tag");
      const contracts = await this.ActionGetDebtsPortoSeguro({
        UserID: this.userAdm ? this.userAdm.UserID : null,
        UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
        tag: tag,
        OS: navigator.platform,
        IP: this.ipUser ? this.ipUser : "",
      });

      if (contracts.debts.length > 0) {
        localStorage.setItem(
          "credor",
          JSON.stringify(contracts.debts[0].credor)
        );
      }

      if (contracts.agreements.length > 0) {
        localStorage.setItem(
          "credor",
          JSON.stringify(contracts.agreements[0].credor)
        );
      }

      if (contracts.debts?.length) {
        contracts.debts.map((item) => {
          item.selected = false;
          item.parcelsIsFromCurrentContract = false;
        });

        this.checkContractRules(contracts);
        contracts.debts?.map((contract) => {
          if ([4, 9, 33].includes(contract.credor)) {
            this.debtsContencioso.push(contract);
          } else if ([5].includes(contract.credor)) {
            this.debtsCdc.push(contract);
          } else if ([2, 7].includes(contract.credor)) {
            this.debts.push(contract);
          }
        });
        this.thereIsDebts = true;
      }

      if (contracts.agreements?.length > 0) {
        this.checkContractRules(contracts);
        this.agreements =
          contracts.agreements.length > 0 ? contracts.agreements : [];
        this.toggleDropdown();
      } else if (!contracts.debts?.length && !contracts.agreements?.length) {
        this.showNoContractsOrAgreements = true;
        this.errorMessage = contracts;
      }

      this.loading = true;
      this.pageLocation = window.location.href;
    } catch {
      this.loading = false;
      this.showNoContractsOrAgreements = true;
    } finally {
      this.loading = false;
    }
  },

  methods: {
    formatValue,
    formatData,
    formatDocument,
    formatStringToLowerCase,
    formatFloatToReal,

    checkContractRules(contracts) {
      contracts.debts?.map((contract) => {
        if (contract.pstPaymentValue <= 0 || contract.pstOriginalValue <= 0)
          this.showDebtsSum = false;
      });
    },

    offersFaturaPropostas(offerInfo) {
      // this.eventClickToParcelarOuEmitirFaturaGTM(
      //   "parcelar-fatura",
      //   offerInfo
      // );
      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard/propostas",
        "propostas"
      );
      this.updateCustomData();
      this.$router.push({
        name: "propostas",
        params: { offerInfo },
      });
    },

    offersFaturaContencioso(event, offerInfo) {
      this.eventClickNegociarOuDescontosDisponiveisGTM(
        event,
        offerInfo.product
      );
      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard/propostasContencioso",
        "propostasContencioso"
      );
      this.updateCustomData();
      this.$router.push({
        name: "propostasContencioso",
        params: { offerInfo },
      });
    },

    offersCdc(agreementInfo) {
      this.eventClickNegociarOuDescontosDisponiveisGTM(
        "negociar",
        agreementInfo.product
      );
      // if (this.checkedParcels.length < 1) {
      //   this.flashMessage.show({
      //     timeout: 1000,
      //     status: "error",
      //     title: "Aviso",
      //     message: "Selecione ao menos uma parcela para negociar!",
      //   });
      //   return;
      // }
      // agreementInfo.installments = this.checkedParcels;
      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard/propostasCdc",
        "propostasCdc"
      );
      this.updateCustomData();
      this.$router.push({
        name: "propostasCdc",
        params: {
          debtsCdc: this.debtsCdc,
          agreements: this.agreements,
          agreementInfo: agreementInfo,
          sumTotalInstallmentsCdc: this.sumTotalInstallmentsCdc,
          // totalInstallmentsCdcWithFees: this.totalInstallmentsCdcWithFees,
        },
      });
    },

    ...mapActions("meusContratos", ["ActionGetDebtsPortoSeguro"]),

    async verBoletos(value) {
      this.loading = true;
      this.eventClickToParcelarOuEmitirFaturaGTM("emitir-fatura", value);
      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard/meus-boletos",
        "meus-boletos"
      );
      this.updateCustomData();
      this.$router.push({
        name: "meusBoletos",
        params: {
          agreementInfo: value,
          fromEmitirFatura: true,
        },
      });
    },

    async verBoletosSemDebts(value) {
      this.loading = true;
      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard/meus-boletos",
        "meus-boletos"
      );
      this.updateCustomData();
      this.$router.push({
        name: "meusBoletos",
        params: { agreementInfo: value, fromAgreements: true },
      });
    },

    eventClickToParcelarOuEmitirFaturaGTM(action, item) {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = `click:button:${action}`;

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: formatStringToLowerCase(item.product),
      };

      window.dataLayer.push({ ...eventData });
    },

    buildAndSetUserData(doc, id, pageLocation, pageName) {
      // const pageName = this.$options.name;
      const userData = {
        cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
        id: id, // Substitua pelo ID real
        page_location: pageLocation,
        pageName: pageName,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
    },

    eventClickNegociarOuDescontosDisponiveisGTM(option, product) {
      const nameProductFormated = formatStringToLowerCase(product);
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = `click:button:${option}`;

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: nameProductFormated,
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    },

    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen; // Alterna entre mostrar/ocultar
    },

    thereIsAgreementCdcClosed(contractNumber) {
      var result =
        this.agreements.filter(function (val) {
          return val.contract === contractNumber;
        }).length > 0;

      return result;
    },

    caractersCount(value) {
      return `Entrada R$
                    ${parseFloat(value.firstInstallmentValue).toLocaleString(
                      "pt-BR",
                      {
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      }
                    )}
                    + ${value.installmentNumber - 1}x de R$
                    ${parseFloat(value.installmentsValue).toLocaleString(
                      "pt-BR",
                      {
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      }
                    )}`.length;
    },
  },

  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>
<style>
#userDropdown::after {
  display: none !important; /* Isso vai remover a seta */
}
html {
  scroll-behavior: smooth;
}

.custom-button {
  margin-bottom: 10px;
  padding: 10px;
  color: #fff;
  width: 100%;
  border-radius: 20px;
  font-size: 18px;
  background-color: #a0a0a0;
  border-style: solid 1px;
  border: 1px solid transparent;
}

.custom-button:hover {
  background-color: #646363;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}
</style>
